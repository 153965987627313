.promo {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: calc(100% - 80px);
  max-width: 1200px;
  min-height: 493px;
  border-radius: 10px;
  background-color: #272727;
}

.promo-title {
  margin: auto;
  max-width: 730px;
  font-size: 50px;
  letter-spacing: -0.04em;
  line-height: 1.16;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .promo {
    width: calc(100% - 40px);
  }

  .promo-title {
    font-size: 40px;
    line-height: 1.3;
    max-width: 680px;
    margin: 350px 0 314px;
  }
}

@media screen and (max-width: 562px) {
  .promo {
    width: calc(100% - 28px);
  }

  .promo-title {
    font-size: 29px;
    line-height: 1.27;
    max-width: 292px;
    margin: 218px 0 213px;
  }
}
