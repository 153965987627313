.techs {
  max-width: 1140px;
  margin: 0 auto;
  padding: 103px 70px;
  background-color: #272727;
}

.techs__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 90px 0 0;
}

.techs__title {
  margin: auto;
  max-width: 600px;
  font-size: 50px;
  letter-spacing: -0.04em;
  line-height: 1.16;
  font-style: normal;
  font-weight: 400;
}

.techs__text {
  max-width: 460px;
  font-size: 14px;
  line-height: 1.42;
  letter-spacing: -0.04em;
  margin: 26px auto 101px;
}

.techs__links {
  display: grid;
  grid-template-columns: repeat(7, 90px);
  grid-gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.techs__list_item {
  display: flex;
  min-width: 90px;
  height: 60px;
  background-color: #303030;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.techs__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: -0.04em;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease 0.1s;
}
.techs__link:hover {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .techs {
    padding: 92px 50px;
  }
  .techs__container {
    padding-top: 78px;
  }
  .techs__text {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 22px;
    margin-bottom: 83px;
  }
  .techs__links {
    grid-template-columns: repeat(7, 84px);
  }
  .techs__list_item {
    height: 57px;
    min-width: 84px;
  }
  .techs__link {
    font-size: 12px;
    line-height: 1.25;
    font-weight: 400;
  }
}

@media screen and (max-width: 690px) {
  .techs__links {
    grid-template-columns: repeat(2, 84px);
  }
}

@media screen and (max-width: 400px) {
  .techs {
    padding: 70px 18px;
  }
  .techs__container {
    padding-top: 60px;
  }
  .techs__title {
    font-size: 30px;
  }
  .techs__text {
    font-size: 11px;
    line-height: 1.45;
    margin-top: 26px;
    margin-bottom: 50px;
  }
}
