.main-section-title {
  margin: 0;
  width: 100%;
  font-size: 22px;
  line-height: 1.22;
  letter-spacing: -0.04em;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 25px;
  border-bottom: 1px solid #dadada;
}

@media screen and (max-width: 320px) {
  .main-section-title {
    font-size: 18px;
    padding-bottom: 28px;
  }
}
