.nav-tab__links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-tab__list_item {
  display: flex;
  width: 96px;
  height: 36px;
  margin: auto 5px 30px;
  background-color: #303030;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.nav-tab__link {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease 0.1s;
}
.nav-tab__link:hover {
  opacity: 0.5;
}

@media screen and (max-width: 320px) {
  .nav-tab__link {
    font-size: 10px;
    line-height: 1.6;
  }
  .nav-tab__list_item {
    margin: auto 3px 15px;
    width: 82px;
    height: 26px;
    border-radius: 6px;
  }
}
