.form {
  padding-top: 10px;
}

.form__label {
  font-size: 10px;
  line-height: 1.2;
  color: #8b8b8b;
}

.form__input {
  margin: 10px 0 15px;
  border: none;
  outline: none;
  background-color: #2f2f2f;
  width: 100%;
  border-radius: 8px;
  font-size: 13px;
  line-height: 1.23;
  padding: 15px;
  box-sizing: border-box;
  color: #ffffff;
}

.form__input:focus {
  outline: 1px solid #3ddc84;
}

.form__input_type_error {
  color: #ee3465;
}

.form__send {
  padding: 13px 125px;
  margin: 77px 0 0 0;
  width: 100%;
  background-color: #4285f4;
  outline: none;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  cursor: pointer;
  transition: opacity 0.3s ease 0.1s;
}

.form__send_disabled {
  background-color: #8b8b8b;
  pointer-events: none;
}

.form__send:hover {
  opacity: 0.5;
}

.form__text {
  font-size: 14px;
  line-height: 1.21;
  font-weight: 400;
  text-align: center;
  color: #8b8b8b;
}

.form__link {
  cursor: pointer;
  font-size: 14px;
  line-height: 1.21;
  color: #4285f4;
  margin-left: 9px;
  transition: opacity 0.3s ease 0.1s;
}

.form__link:hover {
  opacity: 0.5;
}

.form__error {
  display: none;
}

.form__error_visible {
  display: block;
  font-size: 10px;
  line-height: 1.2;
  color: #ee3465;
}

@media screen and (max-width: 450px) {
  .form {
    padding-top: 51px;
  }
  .form__send {
    font-size: 12px;
    padding: 15px 68px;
    margin-top: 142px;
  }
  .form__text {
    font-size: 12px;
  }
  .form__link {
    font-size: 12px;
  }
}
