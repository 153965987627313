.about-me {
  max-width: 1140px;
  margin: 0 auto;
  padding: 107px 70px 97px;
}

.about-me__container {
  display: flex;
}

.about-me__title {
  max-width: 600px;
  font-size: 50px;
  letter-spacing: -0.04em;
  line-height: 1.16;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.about-me__columns {
  display: flex;
  justify-content: space-between;
  padding: 66px 0 0;
}

.about-me__column {
  max-width: 600px;
}

.about-me__subtitle {
  font-size: 18px;
  line-height: 1.11;
  font-weight: 500;
}

.about-me__text {
  margin: 0;
  padding: 9px 0 0;
  font-size: 14px;
  line-height: 1.57;
}

.about-me__list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 100px 0 0;
}

.about-me__list-item {
  margin-right: 20px;
}

.about-me__list-item:last-child {
  margin-right: 0;
}

.about-me__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.21;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease 0.1s;
}

.about-me__link:hover {
  opacity: 0.5;
}

.about-me__photo {
  border-radius: 10px;
  object-fit: cover;
  max-width: 270px;
  max-height: 327px;
  height: 327px;
  width: 100%;
}
@media screen and (max-width: 1010px) {
  .about-me__column {
    max-width: 364px;
  }
}
@media screen and (max-width: 768px) {
  .about-me {
    padding: 89px 50px 85px;
  }
  .about-me__title {
    font-size: 40px;
    line-height: 1;
  }
  .about-me__subtitle {
    font-size: 12px;
    line-height: 1.5;
  }
  .about-me__text {
    font-size: 12px;
    line-height: 1.5;
  }
  .about-me__list {
    padding-top: 88px;
  }
  .about-me__photo {
    max-width: 255px;
    max-height: 307px;
    height: 307px;
  }
}

@media screen and (max-width: 720px) {
  .about-me__column {
    max-width: 292px;
  }
}
@media screen and (max-width: 650px) {
  .about-me__columns {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
  .about-me__column {
    margin-top: 40px;
    max-width: 292px;
  }
  .about-me__photo {
    max-width: 292px;
    max-height: 352px;
    height: 352px;
  }
  .about-me__title {
    font-size: 30px;
    line-height: 1.2;
  }
  .about-me__subtitle {
    font-size: 11px;
    margin-top: 19px;
  }
  .about-me__text {
    font-size: 11px;
  }
  .about-me__list {
    padding-top: 40px;
  }
  .about-me__list-item {
    margin-right: 25px;
  }
}

@media screen and (max-width: 400px) {
  .about-me {
    padding: 70px 14px 70px;
  }
}
