.App {
  font-family: 'Inter', 'Arial', sans-serif;
  font-style: 400;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0 auto;
  width: 100%;
  background-color: #202020;
  display: block;
  min-height: 100vh;
  color: #ffffff;
}
