.header {
  max-width: 1280px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 18px 70px 0px 70px;
  min-height: 74px;
}

.header_hidden {
  display: none;
}

.header__logo {
  width: 38px;
  height: 38px;
  transition: opacity 0.3s ease 0.1s;
}
.header__logo:hover {
  opacity: 0.5;
}

.header__buttons-group {
  margin: 0;
}

.header__button {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  cursor: pointer;
  border: none;
  padding: 8px 20px;
  margin-right: 10px;
  outline: none;
  color: #ffffff;
  background-color: transparent;
  transition: opacity 0.3s ease 0.1s;
}
.header__button:hover {
  opacity: 0.5;
}

.header__button_modified {
  color: #000000;
  border-radius: 3px;
  background-color: #3ddc84;
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .header {
    max-width: 768px;
    padding: 18px 30px 0px 30px;
  }
}

@media screen and (max-width: 400px) {
  .header {
    max-width: 320px;
    padding: 18px 14px 0px 14px;
  }
  .header__button {
    font-size: 10px;
    line-height: 1.6;
    padding: 5px 12px;
    margin-right: 3px;
  }
  .header__button_modified {
    margin-right: 0;
  }
  .header__buttons-group {
    padding-top: 6px;
  }
}
