.portfolio {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 70px;
}

.portfolio__title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: #8b8b8b;
  margin: 0;
}

.portfolio__list {
  list-style: none;
  margin: 0;
  padding: 30px 0 125px;
}

.portfolio__list_item {
  padding: 20px 0;
  border-bottom: 1px solid #424242;
}

.portfolio__list_item:last-child {
  border-bottom: none;
}

.portfolio__link {
  font-size: 30px;
  line-height: 1.66;
  letter-spacing: -0.04em;
  text-decoration: none;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: opacity 0.3s ease 0.1s;
}
.portfolio__link:hover {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .portfolio {
    padding: 0 50px;
  }
  .portfolio__link {
    font-size: 28px;
    line-height: 1.78;
  }
  .portfolio__list {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 515px) {
  .portfolio {
    padding: 0 14px;
  }
  .portfolio__title {
    font-size: 14px;
  }
  .portfolio__list {
    padding-top: 20px;
    padding-bottom: 70px;
  }
  .portfolio__link {
    font-size: 18px;
    line-height: 1.55;
  }
}
