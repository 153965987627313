.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  text-align: center;
  padding-top: 250px;
  padding-bottom: 60px;
  box-sizing: border-box;
}
.not-found__container {
  display: flex;
  flex-direction: column;
}
.not-found__title {
  margin: 0;
  font-weight: normal;
  font-size: 140px;
  line-height: 1.2;
}
.not-found__text {
  margin: 0;
  font-size: 16px;
  line-height: 1.18;
}
.not-found__go-back {
  margin: 190px 0 0 0;

  cursor: pointer;
  font-size: 14px;
  line-height: 1.21;
  color: #4285f4;
}

@media screen and (max-width: 500px) {
  .not-found__title {
    font-size: 80px;
  }
  .not-found__text {
    font-size: 12px;
    line-height: 1.25;
  }
  .not-found__go-back {
    font-size: 12px;
  }
}
