.movies {
  max-width: 1280px;
  box-sizing: border-box;
  padding: 0 70px;
  margin: auto;
  box-sizing: border-box;
}
.movies_error {
  text-align: center;
  color: #8b8b8b;
}

@media screen and (max-width: 768px) {
  .movies {
    padding: 0 30px;
  }
}

@media screen and (max-width: 500px) {
  .movies {
    padding: 0 14px;
  }
}
