.movies-card-list {
  list-style: none;
  margin: auto;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 364px);
  grid-row-gap: 30px;
  grid-column-gap: 24px;
  justify-content: center;
}
.movies-card-list_hidden {
  display: none;
}

@media screen and (max-width: 1000px) {
  .movies-card-list {
    grid-template-columns: repeat(auto-fit, 339px);
  }
}

@media screen and (max-width: 600px) {
  .movies-card-list {
    grid-template-columns: repeat(auto-fit, 300px);
  }
}
