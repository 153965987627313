.navigation__links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigation__pages {
  display: flex;
}
.navigation__list-item {
  margin-right: 20px;
  display: flex;
}
.navigation__list-item:last-of-type {
  margin-right: 0;
}
.navigation__list-item_type-account {
  margin-left: 30px;
}
.navigation__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.28;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease 0.1s;
  display: inline-flex;
  align-items: center;
}
.navigation__link:hover {
  opacity: 0.5;
}

.navigation__link_active {
  text-decoration: underline;
}

.navigation__acc-logo_container {
  box-sizing: border-box;
  width: 26px;
  height: 30px;
  margin-left: 17px;
  background-color: #313131;
  text-align: center;
  border-radius: 4px;
  padding-top: 8px;
}
.navigation__button {
  background-color: transparent;
  outline: none;
  border: none;
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-navigation__wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .mobile-navigation {
    width: 520px;
    height: 100%;
    background-color: #202020;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0;
    top: 0;
  }
  .navigation__links {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 105px;
    padding-bottom: 92px;
    justify-content: space-between;
  }
  .navigation__pages {
    flex-direction: column;
    align-items: center;
  }
  .navigation__list-item {
    display: none;
  }
  .mobile-navigation__list-item {
    display: block;
    margin: 0 0 28px 0;
    max-width: 340px;
  }
  .mobile-navigation__list-item:last-of-type {
    margin-bottom: 0;
  }
  .navigation__button {
    padding: 0;
    display: block;
    align-self: flex-end;
  }
  .navigation__button_close {
    margin: 23px 23px 0 0;
  }
  .navigation__link {
    font-size: 18px;
    line-height: 1.22;
  }
  .navigation__link_type_account {
    font-size: 14px;
    line-height: 1.14;
  }
}

@media screen and (max-width: 500px) {
  .navigation__links {
    padding-top: 84px;
    padding-bottom: 48px;
  }
  .mobile-navigation {
    width: 100%;
  }
}
