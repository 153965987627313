.filter-checkbox {
  display: flex;
  padding: 29px 0 48px;
}

.filter-checkbox__label {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.filter-checkbox__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.filter-checkbox__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.filter-checkbox__slider::before {
  position: absolute;
  content: '';
  height: 8px;
  width: 8px;
  left: 6px;
  bottom: 6px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.filter-checkbox__input:checked + .filter-checkbox__slider {
  background-color: #3ddc84;
}

.filter-checkbox__input:checked + .filter-checkbox__slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.filter-checkbox__sub {
  margin-left: 12px;
  font-size: 14px;
  line-height: 1.28;
}
