.register {
  max-width: 396px;
  margin: auto;
  padding-bottom: 70px;
}

@media screen and (max-width: 450px) {
  .register {
    max-width: 260px;
  }
}
