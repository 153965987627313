.footer {
  margin: auto;
  box-sizing: border-box;
  max-width: 1280px;
  padding: 60px 70px 20px;
}

.footer_hidden {
  display: none;
}

.footer__text {
  color: #8b8b8b;
  margin: 0;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  padding: 0 0 20px;
  border-bottom: 1px solid #424242;
}

.footer__copyright {
  color: #ffffff;
  font-size: 13px;
  line-height: 1.23;
  margin: 20px 0 0;
}

.footer__columns {
  display: flex;
  justify-content: space-between;
}

.footer__column-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__column-list_item {
  margin: 18px 20px 0 0;
}

.footer__column-list_item:last-child {
  margin-right: 0;
}

.footer__link {
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.23;
  transition: opacity 0.3s ease 0.1s;
}

.footer__link:hover {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 60px 30px 20px;
  }
}

@media screen and (max-width: 400px) {
  .footer {
    padding: 60px 10px 10px;
  }
  .footer__text {
    font-size: 12px;
  }
  .footer__columns {
    flex-direction: column-reverse;
    align-items: center;
  }
  .footer__column-list {
    flex-direction: column;
    align-items: center;
    padding-top: 18px;
  }
  .footer__column-list_item {
    margin-right: 0;
    margin-top: 10px;
  }
  .footer__link {
    font-size: 12px;
    line-height: 1.25;
  }
  .footer__copyright {
    color: #8b8b8b;
    font-size: 12px;
    margin-top: 30px;
  }
}
