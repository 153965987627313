.diagram {
  margin-top: 110px;
  display: flex;
}

.diagram__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.diagram__bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #303030;
  height: 36px;
}

.diagram__bg-light {
  background-color: #3ddc84;
}

.diagram__text {
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.21;
  text-align: center;
}

.diagram__text-dark {
  color: #000000;
}

.diagram__sub {
  margin: 14px 0 0 0;
  text-align: center;
  font-size: 14px;
  line-height: 1.21;
  color: #a0a0a0;
}

@media screen and (max-width: 768px) {
  .diagram {
    margin-top: 93px;
  }
}

@media screen and (max-width: 500px) {
  .diagram {
    margin-top: 58px;
  }
  .diagram__text {
    font-size: 11px;
    line-height: 1.18;
  }
  .diagram__sub {
    font-size: 11px;
    line-height: 1.18;
    margin-top: 9px;
  }
}
